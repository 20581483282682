.widget {
	display: flex;
	flex-direction: column;
	background: rgba(255, 255, 255, 0.03);
	width: 100%;
	height: 100%;
	border-radius: 4px;

	align-items: center;

	.widget-title {
		font-weight: bold;
		text-transform: uppercase;
		margin-top: 4px;
		color: rgba(255, 255, 255, 0.42);
		font-size: 0.9vw;
	}

	&.widget-preview {
		.widget-title {
			font-size: 3pt;
		}
	}
}

.world {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
