.number {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	.value {
		font-weight: bold;
		font-size: 5vw;
	}
	.change {
		font-size: 2vw;
		font-weight: bold;
	}
	.positive {
		color: #a0d950;
	}
	.negative {
		color: #ff5555;
	}

	small {
		font-size: 3vw;
	}

	&.widget-preview {
		.value {
			font-size: 1em;
		}

		.change {
			font-size: 0.2em;
		}

		small {
			font-size: 0.3em;
		}
	}
}
