.status-set {
	display: flex;

	table {
		margin: 2vh 3vw;
		font-size: 0.7vw;
		thead {
			th {
				text-align: left;
			}
		}

		tbody {
			.status {
				width: 0.7vw;
				height: 0.7vw;
				border-radius: 100%;

				&.green {
					background-image: linear-gradient(
						180deg,
						#b4ec51 0%,
						#429321 100%
					);
					box-shadow: 0 0 7px 2px rgba(126, 211, 33, 0.66);
				}

				&.yellow {
					background-image: linear-gradient(
						180deg,
						#fad961 0%,
						#f76b1c 100%
					);
					box-shadow: 0 0 7px 2px #f5a623;
				}

				&.red {
					background-image: linear-gradient(
						180deg,
						#f5515f 0%,
						#9f041b 100%
					);
					box-shadow: 0 0 7px 2px #d0021b;
				}
			}

			td {
				padding: 4px;
			}

			tr:first-of-type td {
				padding-top: 10px;
			}

			.status-row {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	&.widget-preview {
		table {
			margin: 2px 3px;
			font-size: 2px;

			tbody {
				.status {
					width: 2px;
					height: 2px;
					border-radius: 100%;

					&.green {
						box-shadow: 0 0 1px 1px rgba(126, 211, 33, 0.66);
					}

					&.yellow {
						box-shadow: 0 0 1px 1px #f5a623;
					}

					&.red {
						box-shadow: 0 0 1px 1px #d0021b;
					}
				}

				td {
					padding: 1px;
				}

				tr:first-of-type td {
					padding-top: 2px;
				}
			}
		}
	}
}
