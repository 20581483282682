.sentry-error-list {
	display: flex;

	table {
		margin: 1.5vw 3vw;
		font-size: 0.7vw;
		thead {
			th {
				text-align: left;
			}
		}

		tbody {
			td {
				padding: 0.2vw;
			}

			tr:first-of-type td {
				padding-top: 1vw;
			}
		}
	}
	&.widget-preview {
		table {
			margin: 1.5px 3px;
			font-size: 2.4px;
			tbody {
				td {
					padding: 1px;
				}

				tr:first-of-type td {
					padding-top: 1px;
				}
			}
		}
	}
}
