#dashboard {
	display: grid;
	width: calc(100vw - 8px);
	margin: 4px;
	height: calc(100vh - 54px);
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(12, 1fr);
	grid-gap: 4px;
	margin-top: 48px;

	#loading-status,
	#error-status {
		display: flex;
		width: 100vw;
		height: 100vh;
		align-items: center;
		justify-content: center;
	}

	&.dashboard-preview-mode {
		width: 360px;
		height: 270px;
		border: solid 1px rgba(255, 255, 255, 0.15);
		border-radius: 8px;
		margin-top: 16px;
	}
}
