.social-media-feed {
	margin-top: 1vw;
	overflow-y: scroll;

	.feed-item {
		display: flex;
		flex-direction: column;
		width: calc(100% - 8px);
		margin: 8px 4px;
		font-size: 0.8vw;
		background: rgba(255, 255, 255, 0.03);
		border-radius: 4px;

		.feed-item-user {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 0.4vw;
			font-weight: bold;
		}

		.feed-item-avatar {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 1.4vw;
			height: 1.4vw;
			border-radius: 100%;
			background-image: linear-gradient(180deg, #fbd249 0%, #f5a623 100%);
			font-weight: bold;
			font-size: 0.7vw;
			color: #111111;
			text-align: center;
			margin-right: 0.5vw;
		}

		.feed-item-content {
			margin: 0.5vw;
		}

		.feed-item-timestamp {
			margin: 0.4vw;
			font-size: 0.7vw;
			color: rgba(255, 255, 255, 0.5);
		}
	}

	&.widget-preview {
		margin-top: 10px;

		.feed-item {
			width: calc(100% - 8px);
			margin: 4px 2px;
			font-size: 4px;
			border-radius: 1px;

			.feed-item-user {
				margin: 1px;
			}

			.feed-item-avatar {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 4px;
				height: 4px;
				border-radius: 100%;
				font-size: 2px;
				margin-right: 2px;
			}

			.feed-item-content {
				margin: 2px;
			}

			.feed-item-timestamp {
				margin: 1px;
				font-size: 3px;
			}
		}
	}
}
